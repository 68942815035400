<template>

  <div class="d-flex flex-column align-items-center">
    <b-card style="min-width: 480px; min-height: 128px;" class="bg-white w-50 mt-16 mx-auto">

      <div v-if="is_mobile">

        <b-row align-h="center" v-if="!pending_payment">
          <button style="font-size: 22px; min-height: 35px; min-width: 280px;" class="btn btn-success btn-lg mt-4" @click.prevent="open_swish_clicked()">{{$t('PUBLIC.PAYMENT.OPEN_SWISH')}}</button>
        </b-row>

      </div>

      <div v-else class="mb-16 mx-auto">
        <!-- Desktop option -->

        <b-row v-if="order === null || !order.swish_request_id">
          <b-col>
            <div class="d-flex justify-content-center mb-8 mt-8">
              <span class="text-center">{{ $t('PUBLIC.PAYMENT.LOADING_SWISH') }}</span>
            </div>

            <div class="d-flex justify-content-center mb-8 mt-8">
              <b-spinner label="Loading..."></b-spinner>
            </div>
          </b-col>
        </b-row>

        <b-row v-else>
          <b-col style="text-align: center;">

            <b-row>
              <b-col cols="12">
                <span>{{ $t('PUBLIC.PAYMENT.SCAN_QR_INFO') }}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-img class="mt-8 mx-auto center-block" :src="swish_qr_link" style="max-width: 330px;" />    
              </b-col>
            </b-row>
          </b-col>
        </b-row>

      </div>

    </b-card>

    <PaymentStatusComponent
      v-if="order && pending_payment"
      :order="order"
    />
  </div>
</template>

<script>
import axios from 'axios';
import PaymentComplete from '@/view/components/PaymentComplete.vue';
import PaymentOrderDetails from '@/view/components/PaymentOrderDetails.vue';
import PendingPaymentInfo from '@/view/components/PendingPaymentInfo.vue';
import PaymentStatusComponent from '@/view/pages/members/payment/PaymentStatusComponent.vue';


import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_domain_url, get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

export default {
  name: 'SwishPaymentComponent',
  props: ['order'],
  emits: ['updated', 'start_polling'],
  mixins: [ toasts ],
  components: {
    PaymentComplete,
    PaymentOrderDetails,
    PendingPaymentInfo,
    PaymentStatusComponent
  },
  computed: {

    swish_qr_link() {
      if (!this.local_order) {
        return null;
      }

      const domain = get_domain_url();

      if (domain.indexOf('localhost') >= 0) {
        return `http://localhost:3020/swish-qr/${this.local_order.shop_order_id}`;
      }

      return `${domain}/swish-qr/${this.local_order.shop_order_id}`;
    },

    min_width() {
      return {
        'min-width': is_mobile()  ? '460px' : '640px'
      };
    },

    is_mobile() {
      return is_mobile();
    },

    callback_url() {
      return get_domain_url() + `/return-all-payment/${this.local_order.token}/${this.local_order.shop_order_id}`
    }

  },
  data() {
    return {
      pending_payment: false,

      local_order: null
    };
  },

  mounted() {

    if (this.order) {
      this.local_order = { ...this.order };
    }

    if (!is_mobile()) {
      this.request_swish_qr_code();
    }

  },
  watch: {
    order: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_order = { ...this.order };
      }
    },
  },
  methods: {

    async request_swish_qr_code() {

      if (!this.local_order) {
        console.error('no shop order to request');
        return;
      }

      try {
        // 1. Make Api request for Swish QR
        const res = await axios.post(`/swish/merchant/request/${this.local_order.shop_order_id}`, { qr: true });

        if (res.status === 201) {
          this.$emit('updated', res.data);
          this.$emit('start_polling');
        }
        else {
          console.error('request_swish_qr_code invalid status: ' + res.status);
        }
      }
      catch (err) {
        console.error('request_swish_qr_code error', err);
      }
    },


    get_callback_url() {
      if (!this.local_order || !this.local_order.swish_token) {
        console.log('callback url, this.order', this.local_order)
        return null;
      }

      return get_domain_url() + `/return-all-payment/${this.local_order.token}/${this.local_order.shop_order_id}`
    },

    get_swish_app_string() {
      if (!this.local_order || !this.local_order.swish_token) {
        console.log('app string, this.order', this.local_order)
        return null;
      }

      return `swish://paymentrequest?token=${this.local_order.swish_token}&callbackurl=${encodeURIComponent(this.get_callback_url())}`;
    },

    async open_swish_local_app() {

      while (this.get_callback_url() === null) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      while (this.get_swish_app_string() === null) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      this.$nextTick(() => {

        const swish_app = this.get_swish_app_string();

        console.log('swish url', swish_app);

        window.location = swish_app;
      });


    },

    async open_swish_clicked() {

      if (!this.local_order) {
        console.error('no shop order to request');
        return;
      }

      try {
        const res = await axios.post(`/swish/merchant/request/${this.local_order.shop_order_id}`, { qr: false });

        if (res.status === 201) {
          this.$emit('updated', res.data);
          this.$emit('start_polling');

          this.open_swish_local_app();
        }
        else {
          console.error('request_swish_qr_code invalid status: ' + res.status);
        }
      }
      catch (err) {
        console.error('request_swish_qr_code error', err);
      }

      this.pending_payment = true;
    },

  }
};
</script>
