<template>
  <div class="d-flex flex-column align-items-center">

    <h4 class="mb-4">{{ $t('PUBLIC.PAYMENT.SELECT_PAYMENT') }}</h4>

    <div v-if="!has_swish && !has_stripe">
      <b-card style="min-width: 400px;" class="mt-16 mb-32">
        <div class="col-12 text-center">
          <i class="fa fa-minus-circle" style="font-size: 42px; color: #fe0000;"></i>
        </div>

        <div class="d-flex justify-content-center mb-8 mt-8">
          <span class="text-center" style="font-weight: bold;">{{ $t('PUBLIC.PAYMENT.NO_PAYMENT_METHODS') }}</span>
        </div>

        <div class="d-flex justify-content-center mb-8 mt-8">
          <span class="text-center">{{ $t('PUBLIC.PAYMENT.CONTACT_SUPPORT') }}</span>
        </div>

      </b-card>
    </div>

    <b-card v-else style="border: none; clear: both;" class="bg-white rows-of-select">

      <div :class="payment_row_classes" v-if="has_stripe" @click="selected_payment_method = 'STRIPE'">

        <div class="row-left">

          <label class="kt-option kt-option kt-option--plain" style="margin-top: 19px; float: left;">
            <span class="kt-option__control">
              <label class="radio radio-outline radio-success mr-5">
                <input
                  v-model="selected_payment_method"
                  type="radio"
                  value="STRIPE"
                  name="payment-method"
                />
                <span class="mr-2"></span>
              </label>
            </span>
            <span class="kt-option__label">
              <span class="kt-option__head">
                <span class="kt-option__title">
                  {{ $t('PUBLIC.PAYMENT.CREDITCARD') }}
                </span>
              </span>
            </span>
          </label>

        </div>

        <div class="row-right" style="float: right; margin-right: 8px;">

          <b-img src="/assets/images/visa_master.png" style="height: 40px; width: 100px; margin-top: 12px;"/>

        </div>

      </div>


      <div :class="payment_row_classes" v-if="has_swish" @click="selected_payment_method = 'SWISH'">

        <div class="row-left">

          <label class="kt-option kt-option kt-option--plain" style="margin-top: 19px; float: left;">
            <span class="kt-option__control">
              <label class="radio radio-outline radio-success mr-5">
                <input
                  v-model="selected_payment_method"
                  type="radio"
                  value="SWISH"
                  name="payment-method"
                />
                <span class="mr-2"></span>
              </label>
            </span>
            <span class="kt-option__label">
              <span class="kt-option__head">
                <span class="kt-option__title">
                  Swish
                </span>
              </span>
            </span>
          </label>

        </div>

        <div class="row-right" style="float: right; margin-right: 8px;">

          <b-img src="/assets/images/swish.svg" style="width: 60px; height: 60px;"/>

        </div>

      </div>

    </b-card>
  </div>
</template>

<script>
import axios from 'axios';
import PaymentComplete from '@/view/components/PaymentComplete.vue';
import PaymentOrderDetails from '@/view/components/PaymentOrderDetails.vue';
import PendingPaymentInfo from '@/view/components/PendingPaymentInfo.vue';

import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';

export default {
  name: 'SwishPaymentPage',
  props: ['order'],
  emits: ['selected'],
  mixins: [ toasts ],
  components: {
    PaymentComplete,
    PaymentOrderDetails,
    PendingPaymentInfo
  },
  computed: {

    payment_row_classes() {
      if (this.has_swish && this.has_stripe) {
        return 'payment-select-row two-rows';
      }

      return 'payment-select-row';
    },


    has_stripe() {
      return this.order && this.order.has_stripe;
    },

    has_swish() {
      return this.order && this.order.has_swish;
    },

    min_width() {
      return {
        'min-width': is_mobile()  ? '460px' : '640px'
      };
    },

  },
  data() {
    return {
      selected_payment_method: null
    };
  },
  mounted() {

  },
  watch: {
    selected_payment_method() {
      this.$emit('selected', this.selected_payment_method);
    }
  },
  methods: {

  }
};
</script>

<style lang="css" scoped>

.row-left {
  min-width: 200px;
  min-height: 70px;
  float: left;
}

.row-right {
  float: left;
  min-height: 70px;
}

.payment-select-row:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.payment-select-row::after {
  clear: both;
}

.payment-select-row {
  padding-left: 16px;
  padding-top: 8px;
  float: left;
  min-width: 380px;
  display: block;
  border: 1px solid #b8b3eb;
  background-color: white;

  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (min-width: 1025px) {
    min-width: 420px;
  }
}

.payment-select-row:hover {
  background-color: #deffdd;
  cursor: pointer;
}


.payment-select-row:nth-last-child(-n + 1) {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.two-rows:nth-last-child(-n +1) {
  border-top: none;
}

.card-body {
  @media (max-width: 1024px) {
    padding: 0px;
    width: 100%;
  }
  @media (min-width: 1025px) {
    width: 480px;
  }
}


.kt-option {
  display: flex;
  padding: 1.4em;
  border: 1px solid #ebedf2;
  border-radius: 4px;
}
.kt-option .kt-option__control {
  width: 2.7rem;
  padding-top: 0.1rem;
}
.kt-option .kt-option__control .kt-radio {
  display: block;
}
.kt-option .kt-option__label {
  width: 100%;
}
.kt-option .kt-option__label .kt-option__head {
  display: flex;
  justify-content: space-between;
}
.kt-option .kt-option__label .kt-option__head .kt-option__title {
  font-size: 1.1rem;
  font-weight: 400;
  color: #191919;

  font-size: 17px;
}
.kt-option .kt-option__label .kt-option__head .kt-option__focus {
  font-size: 1.1rem;
  font-weight: 500;
  color: #48465b;
}
.kt-option .kt-option__label .kt-option__body {
  display: block;
  padding-top: 0.7rem;
  font-size: 0.9rem;
  color: #74788d;
}
.kt-option.kt-option--plain {
  border: 0;
  padding: 0;
  margin-top: -0.2rem;
  margin-bottom: 2rem;
  align-items: center;
}
.kt-option.kt-option--plain .kt-option__control {
  vertical-align: middle;
  width: 2.7rem;
}
.kt-option.kt-option--plain .kt-option__control .kt-radio {
  margin-bottom: 1.6rem;
}
.kt-option.kt-option--plain .kt-option__label .kt-option__body {
  padding-top: 0.2rem;
}
.is-valid .kt-option {
  border-color: #1dc9b7;
}
.is-invalid .kt-option {
  border-color: #fd397a;
}

.has-success .kt-option .kt-option__label .kt-option__head .kt-option__title {
  color: #1dc9b7;
}

.has-error .kt-option .kt-option__label .kt-option__head .kt-option__title {
  color: #ffb822;
}
</style>
